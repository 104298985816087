import { isEmpty } from 'lodash';
import type { ReactElement } from 'react';

import { BaseBlock } from '@/components/base-block';
import { getListItemsClassName } from '@/utils/get-list-items-class-name';
import { getModelURL } from '@/lib/datocms/utils/get-model-url';
import { Icon } from '@/components/icon';
import { Link } from '@/components/link';
import type { TopTasksBlockType } from '@/lib/types';
import { HUNDRED_YEAR_TAKEOVER } from '@/lib/constants';
import clsx from 'clsx';

const TopTasksBlock = ({ title, items }: TopTasksBlockType): ReactElement | null => {
  if (isEmpty(items)) {
    return null;
  }
  return (
    <BaseBlock className="top-tasks">
      {!isEmpty(title) && (
        <h3 className={clsx('pre-heading', HUNDRED_YEAR_TAKEOVER ? 'hundred-year-theme' : undefined)}>{title}</h3>
      )}
      <ul>
        {items.map((item, index) => {
          return (
            <li key={item.id} className={getListItemsClassName(index, items.length)}>
              <Link
                className={clsx('body-2', HUNDRED_YEAR_TAKEOVER ? 'hundred-year-theme' : undefined)}
                href={getModelURL(item.link)}
                analytics={{ context: 'Top Tasks', text: item.title }}
              >
                <div className="item-wrapper">
                  {!isEmpty(item.icon) && (
                    <Icon
                      name={item.icon}
                      className={clsx('top-tasks-icon', HUNDRED_YEAR_TAKEOVER ? 'hundred-year-theme' : undefined)}
                    />
                  )}
                  <span
                    className={clsx(
                      'display-text field-displaytext',
                      HUNDRED_YEAR_TAKEOVER ? 'hundred-year-theme' : undefined
                    )}
                  >
                    {item.title}
                  </span>
                </div>
              </Link>
            </li>
          );
        })}
      </ul>
    </BaseBlock>
  );
};

export { TopTasksBlock };
